import React from "react";
import PageWrapper from "../components/PageWrapper";
import HeroComponent from "../components/HeroComponent";
import LipsGlance from "../components/glance.js";
import imgVideo from "../assets/image/jpeg/lip-filler-doctor.png";
import Testimonial from "../sections/index/indexreviews.js";
import Saveface from '../components/save.js';
import Trend from '../components/trends.js';
import Contentlips from "../sections/lips/lipscontent.js";
import Second from "../sections/lips/lipsecond.js";
import LipsFaq from "../sections/lips/lipsfaq.js";
import LipsSlider from "../sections/lips/lipsbefore.js";
import Head from "../components/head";
import Pro from '../sections/lips/lipsteps.js';
import Find from '../components/NewFind.js'; 
import Award from "../components/NewToxane.js";
import { JsonLd } from '../components/JsonLd';
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";

const LipsFillerPage = () => {
 
  return (
    <>
    <Head title="Natural Lip Filler With Plumping Hyaluronic Acid" image="/images/dermamina-home.jpg"  description="Enhance your smile and rejuvenate your look with lip filler treatments at Dermamina. Get soft, fuller, hydrated lips tailored to your aesthetic desires." keywords="Lip fillers, Lip augmentation, Dermal fillers, Lip enhancement, Lip injections, Plump lips, Lip filler London, Natural lip augmentation, Lip rejuvenation, Non-surgical lip enhancement, 0.5 ml Lip Filler, 1ml Lip filler, 0.5 and 1ml lip filler, make lips bigger, lip filler before and after"/>


    <JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>



<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Lip Filler London',
item:'https://www.dermamina.com/lip-filler',
},

]
}}
</JsonLd>


<JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What does the Lip Filler Treatment London involve?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The treatment is carried out by injecting dermal fillers strategically to the lips, with a needle, delivering results that are desirable and suitable for the patient. Treatment results are instant and the treatment time typically lasts between 10-15 minutes"
            }
          },
          {
            "@type": "Question",
            "name": "Is Lip Filler Treatment safe?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes. Hyaluronic acid based dermal fillers are derived naturally and not animal based. It is known as one of nature’s most versatile and is made with extensive research in the laboratory. Over time the fillers get dissolved naturally in the skin as the body is able to break the filler down and results of the treatment gradually fade. The filler breaks down and is re-absorbed by the body, just like the natural occurring hyaluronic acid in the skin. Hyaluronic acid has gained a reputation for being safe, effective and a good alternative to surgery."
            }
          },
          {
            "@type": "Question",
            "name": "How much is Lip Filler treatment in the UK?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "At Dermamina London prices are dependent on quantity chosen:Up to 0.7ml - £250, 1ml - £300, 2ml - £480. If you are unsure of the quantity to choose from, you can drop our friendly team a message so we can assess and advice you accordingly"
            }
          },
          {
            "@type": "Question",
            "name": "Does Lip Filler Hurt?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Numbing cream is applied to the area to be injected and only when it is numbed, the treatment is done, making your experience as comforting as possible. Based on all our previous patients, the pain was mild and some patients felt no pain at all. Slight tenderness, redness and swelling are to be expected right after the treatment, and sometimes mild bruising. However, our practitioner will talk you through everything during the consultation on the day."
            }
          }
        ]
      }}
    </JsonLd>

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "AggregateRating",
        "ratingValue": "4.9",
        "reviewCount": "600"
      }}
    </JsonLd>




    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "Service",
        "serviceType": "Lip Filler",
        "provider": {
          "@type": "Organization",
          "name": "Dermamina",
          "url": "https://www.dermamina.com"
        },
        "areaServed": {
          "@type": "Place",
          "name": "London"
        },
        "description": "Lip Filler Treatment London",
        "offers": {
          "@type": "Offer",
          "url": "https://www.dermamina.comlip-filler",
          "priceCurrency": "GBP",
          "price": "250",
          "eligibleRegion": {
            "@type": "Place",
            "name": "London"
          }
        }
      }}
    </JsonLd>

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": "Lip Filler - Natural, Hydrated and Fuller Looking Lips",
        "description": "Video explaining what is Lip Filler?",
        "thumbnailUrl": "https://www.dermamina.com/static/lip-filler-doctor-a1756675ac6ccea796c3ffff40cbb1f2.png",
        "uploadDate": "2024-05-17T17:00:00+01:00",
        "embedUrl": "https://youtu.be/LBCVCG2eDdg?si=DhWqH5Ri19zlAwz6",
        "contentUrl": "https://www.youtube.com/watch?v=LBCVCG2eDdg"
      }}
    </JsonLd>

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": [
          {
            "@type": "ImageObject",
            "position": 1,
            "name": "Before and After Lip Filler",
            "contentUrl": "https://www.dermamina.com/static/lip15-02a1d161aa43a875f6273bba5d46d6fc.jpg",
            "description": "Image showing hair condition before and after Lip Filler treatment."
          },
          {
            "@type": "ImageObject",
            "position": 2,
            "name": "Before and After Lip Filler",
            "contentUrl": "https://www.dermamina.com/static/lip22-cc8194770759b8a149d02dd06bb5f472.jpg",
            "description": "Image showing hair condition before and after Lip Filler treatment."
          }
        ]
      }}
    </JsonLd>


<PageWrapper headerDark>
<HeroComponent
    itext='<span style="color: #1a1a1a;">Lip Filler</span> <span style="color: #00aec7;">Treatment</span>'
    secondText="Natural, hydrated and fuller looking lips"
    videoUrl="https://www.youtube.com/watch?v=LBCVCG2eDdg" // Specify your video URL here
    videoAlt="Lip Filler Treatment Video"
    imgVideo={imgVideo} // Specify your image URL here
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
<LipsGlance
   best="1 Treatment"
  bestalt="best lip filler treatment"
  resultstext="Approx. 6 Months"
  resultsalt="Lip filler results"
  costdata="From £250"
  costalt="Lip Filler Cost"
  timedata="10-15 Minutes"
  timealt="Lip Filler Duration Time"
  workdata="Immediately"
  workalt="lip filler downtime work"
  paindata="Mild"
  painalt="Lip Filler Pain"
  />
 </Col>
  </Row>
  </Container>
  </Section>
<LipsSlider />
<Contentlips />
<Testimonial />
<Award />
<Pro />
<LipsFaq />  
<Second />
   
<Find />
<Saveface />
<Trend />
</PageWrapper>

    </>
  );
};
export default LipsFillerPage;
